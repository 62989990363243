<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div class="sign-in">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

			<!-- Sign In Form Column -->
			<a-col :span="24" :md="12" :lg="{span: 12, offset: 0}" :xl="{span: 6, offset: 2}" class="col-form">
				<h1 class="mb-15">Masuk</h1>
				<h5 class="font-regular text-muted">Masukkan akun untuk memulai aktivitas</h5>

				<div v-if="alert.msg">
					<a-alert :message="alert.msg" :type="alert.type" closable></a-alert>
				</div>

				<!-- Sign In Form -->
				<a-form
					id="components-form-demo-normal-login"
					:form="form"
					class="login-form"
					@submit="handleSubmit"
					:hideRequiredMark="true"
				>
					<a-form-item class="mb-10" label="Username" :colon="false">
						<a-input 
						v-decorator="[
						'username',
						{ rules: [{ required: true, message: 'Masukkan username anda!' }] },
						]" placeholder="Username" />
					</a-form-item>
					<a-form-item class="mb-5" label="Password" :colon="false">
						<a-input
						v-decorator="[
						'password',
						{ rules: [{ required: true, message: 'Masukkan password anda!' }] },
						]" type="password" placeholder="Password" />
					</a-form-item>
					<!-- <a-form-item class="mb-10">
    					<a-switch v-model="rememberMe" /> Remember Me
					</a-form-item> -->
					<br/>
					<a-form-item>
						<a-button type="primary" block html-type="submit" class="login-form-button">
							MASUK
						</a-button>
					</a-form-item>
				</a-form>
				<!-- / Sign In Form -->

				<!-- <p class="font-semibold text-muted">Don't have an account? <router-link to="/sign-in" class="font-bold text-dark">Sign Up</router-link></p> -->
			</a-col>
			<!-- / Sign In Form Column -->

			<!-- Sign In Image Column -->
			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
				<img src="images/img-signin.jpg" alt="">
			</a-col>
			<!-- Sign In Image Column -->

		</a-row>
		
	</div>
</template>

<script>

	import { processMessage } from '../helpers';

	export default ({
		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				alert: { type: '', msg: '' },
				rememberMe: true,
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						
						this.$http.post(process.env.VUE_APP_API + '/user/login', {
							username: values.username,
							password: values.password
						})
						.then((res) => {
						  	
						  	this.alert = processMessage(res)

						  	let token = res.data.data.access_token

						  	this.$store.dispatch('setTokenAction', token)
					  		localStorage.setItem('token', token);

							this.$router.push({name: 'Dashboard'})
						})
						.catch(err => {
							this.alert = processMessage(err.response)
						})
					}
				});
			},
		},
	})

</script>

<style lang="scss">
	body {
		background-color: #ffffff;
	}
</style>