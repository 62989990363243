import router from '../router'

export function processMessage(response) {
  if(response.status == 200 || response.status == 201){
    return {
      type: 'success',
      msg: response.data.message,
    }

  } else if(response.status == 422 || response.status == 500 || response.status == 400 || response.status == 404){
    return {
      type: 'error',
      msg: response.data.message
    }

  } else if(response.status == 401){
    // localStorage.removeItem('user')
    // router.push({name: 'Sign-In'})

    return {
      type: 'error',
      msg: response.data.message
    }

  } else {
    return {
      type: 'error',
      msg: error
    }
  }
}

export function setInitialValue(data) {
  for(const key in data){
    data[key] = '';
  }

  return data
}

export function renderStatusColor(status) {
  let color = 'color: '
  if(status == 'ONGOING'){
    color += '#f48024'
  } else if(status == 'CREATED'){
    color += '#1890ff'
  } else if(status == 'CANCELLED'){
    color += 'firebrick'
  } else if(status == 'COMPELTED'){
    color += '#469600'
  } else {
    color += '#000000'
  }

  return color
}

export function formatRupiah(angka, prefix){
  var reverse = angka.toString().split('').reverse().join(''),
  ribuan  = reverse.match(/\d{1,3}/g);
  ribuan  = ribuan.join('.').split('').reverse().join('');

  return prefix + ribuan
}